import {clone, getTitleById, pushHistory, sortArray, urlrewriting} from "../../helpers/Tools";
import {store} from "../../store/store";
import {setRedirectError} from "../../actions";
import {Typography} from "@material-ui/core";
import React from "react";
import Parser from "html-react-parser";
import OpenItem from "../../helpers/OpenItem";

/**
 * Permet d'effectuer une redirection vers le bon composant si il est share
 * @param href
 * @param RoutePathArray
 * @param prevProps
 * @returns {string|[]}
 */
export function redirect(href, RoutePathArray, prevProps) {
    let url_query = new URL(href);
    let id = url_query.searchParams.get("sharecontent");

    if (id) {
        let myTab = []
        let fullTitle = getTitleById(RoutePathArray, id, myTab);
        let url = [];

        fullTitle.forEach(function (element) {
            url.push(urlrewriting(element.title));
        });

        if(url.length > 0){
            pushHistory("/" + url.join('/'))
        } else {
            if(!prevProps.application.redirectError){
                store.dispatch(setRedirectError(true))
            }
        }
    }
}


export function openItem(id) {
    let RoutePathArray = store.getState().routes.RoutePathArray;

    if (id) {
        let myTab = []
        let fullTitle = getTitleById(RoutePathArray, id, myTab);
        let url = [];

        fullTitle.forEach(function (element) {
            url.push(urlrewriting(element.title));
        });

        if(url.length > 0){
            pushHistory("/" + url.join('/'))
        } else {

        }
    }
}

export function Copyright(data) {

    var label = "";
    var openItem = null;
    let footerContentId = store.getState().application.getApp.webParams.footer_content_id;
    let footerContentTitle = store.getState().application.getApp.webParams.footer_content_title;
    if(footerContentId != null){
        if(footerContentTitle == null || footerContentTitle == ""){
            footerContentTitle = " Mentions légales";
        }
        openItem = <OpenItem contentToRedirect={footerContentId}>{footerContentTitle}</OpenItem>
    }
    else {
        openItem = null
    }

    return (
        <Typography variant="body1" color="inherit" align="center">
            {'Copyright © '}
            {data.title}
            {' '}
            {new Date().getFullYear()}
            {'.'}
            {openItem}
        </Typography>
    );
}

/**
 *  supprime les doublons en fonction de la key (ex: item => item.id)
 * @param data
 * @param key
 * @returns {array}
 */
export function removeDuplicates(data, key) {
    return [
        ...new Map(data.map(item => [key(item), item])).values()
    ]
};

export function constructNodeProduct(data_market, content) {
    let nodesTmp = [];

    if(content.category_product_id && content.supplier_product_id){
        let category = data_market.categories.find((element) => element.id === content.category_product_id);
        let supplier = data_market.suppliers.find((element) => element.id === content.supplier_product_id);

        if(typeof category !== 'undefined' && typeof supplier !== 'undefined'){
            let products = category.products.filter(value => supplier.products.includes(value));

            products.forEach((product) => {
                if (typeof data_market.products.find((element) => element.id === product) !== 'undefined'){
                    nodesTmp.push(data_market.products.find((element) => element.id === product));
                }
            })
        }
    } else {
        if(content.category_product_id){
            let category = data_market.categories.find((element) => element.id === content.category_product_id);

            if(typeof category !== 'undefined'){
                category.products.forEach((product) => {
                    if (typeof data_market.products.find((element) => element.id === product) !== 'undefined'){
                        nodesTmp.push(data_market.products.find((element) => element.id === product));
                    }
                })
            }
        }

        if(content.supplier_product_id){
            let supplier = data_market.suppliers.find((element) => element.id === content.supplier_product_id);

            if(typeof supplier !== 'undefined'){
                supplier.products.forEach((product) => {
                    if (typeof data_market.products.find((element) => element.id === product) !== 'undefined'){
                        nodesTmp.push(data_market.products.find((element) => element.id === product));
                    }
                })
            }
        }
    }

    return removeDuplicates(nodesTmp, item => item.id);
}

export function constructNodeAnnounce(content, announces, prefix, announce_type){
    let nodesTmp = [];

    sortArray(announces, "id").forEach((announce) => {

        //ajout d'information pour la construction future du composant announce

        let announce_obj = clone(announce);

        announce_obj.parent_id = content.id;
        announce_obj.type = 'announce';
        announce_obj.accessibility = content.accessibility;
        announce_obj.icon_name = "fa-bullhorn";
        announce_obj.media = content.urlMedia;
        announce_obj.is_visible = content.is_visible;
        announce_obj.id = prefix+"-"+announce.id;
        announce_obj.announce_id= announce.id;
        announce_obj.announce_type = announce_type;

        nodesTmp.push(announce_obj)

    })

    return nodesTmp;
}
